<script setup>
import MobileListingList from '~/pages/mobile/account/listings/index.vue';
import MobileShowTeammate from '~/pages/mobile/teammates/show.vue';
import MobileBlogCategory from '~/pages/mobile/blogs/index.vue';
import MobileBlogPost from '~/pages/mobile/blogs/show.vue';
import { useSsrFetch } from "../composables/useFetch";
import { useRoute } from 'vue-router';

const route = useRoute();
const result = ref({});

const fetchData = async () => {
  const qs = btoa(JSON.stringify(route.query));
  const slug = btoa(JSON.stringify(route.params.slug));

  return useSsrFetch(`pages/slug?slug=${slug}&qs=${qs}`);
}

result.value = await fetchData();

if (!result.value?.model) {
  showError({
    statusCode: 404,
    statusMessage: "Page Not Found"
  })
}

</script>
<template>
  <template v-if="result?.model === 'ListingList'">
    <MobileListingList :meta="result?.meta" :data="result?.data" :fetchData="fetchData" />
  </template>

  <template v-else-if="result?.model === 'Teammate'">
    <MobileShowTeammate :meta="result?.meta" :data="result?.data" :fetchData="fetchData"></MobileShowTeammate>
  </template>

  <template v-else-if="result?.model === 'BlogCategory'">
    <MobileBlogCategory :meta="result?.meta" :data="result?.data" :fetchData="fetchData" />
  </template>

  <template v-else-if="result?.model === 'BlogPost'">
    <MobileBlogPost :meta="result?.meta" :data="result?.data" />
  </template>
</template>
